/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
import { Injectable } from '@angular/core';

interface UndrupalObject {
  [key: string]: any;
}

interface DrupalObjectValue {
  value?: any;
  target_id?: any;
  format?: string;
  target_type?: string;
}

export interface DrupalObject {
  [key: string]: DrupalObjectValue[];
}

@Injectable({
  providedIn: 'root',
})
export class PmCoreDrupalMapperService {
  drupalizeObject(payload: any, skip: string[] = [], skipEmpty = true) {
    const drupalized_payload = {} as DrupalObject;
    const dateFields = ['submission_date'];
    for (const param in payload) {
      if (
        payload.hasOwnProperty(param) &&
        (payload[param] !== '' || !skipEmpty) &&
        param !== 'id'
      ) {
        if (skip.indexOf(param) !== -1) {
          continue;
        }
        drupalized_payload[param] = [];
        if (
          param === 'service' ||
          param === 'assign_to' ||
          param === 'seller' ||
          param === 'application' ||
          param === 'domain_zone' ||
          param === 'domain_contact' ||
          param === 'promocode' ||
          param === 'reference' ||
          param === 'related_documents'
        ) {
          if (payload[param] && payload[param].hasOwnProperty('target_id')) {
            drupalized_payload[param].push(payload[param]);
          } else {
            drupalized_payload[param].push({ target_id: payload[param] });
          }
        } else if (
          param === 'file' ||
          param === 'screenshot' ||
          param === 'tm_image'
        ) {
          if (payload[param] === null) {
            drupalized_payload[param] = [];
          } else if (payload[param].hasOwnProperty('target_id')) {
            drupalized_payload[param].push(payload[param]);
          } else {
            drupalized_payload[param].push({ target_id: payload[param] });
          }
        } else if (param === 'type') {
          drupalized_payload[param].push({ target_id: payload[param] });
        } else if (
          param === 'referrer' ||
          param === 'user_id' ||
          param === 'partner' ||
          param === 'classes_details'
        ) {
          drupalized_payload[param] = [payload[param]];
        } else if (param === 'event_type') {
          drupalized_payload[param] = [payload[param]];
        } else if (param === 'classes') {
          if (typeof payload[param] === 'string') {
            payload[param] = payload[param].replace(/\s/g, '').split(',');
            if (payload[param].some(isNaN)) {
              payload[param] = [1];
            }
          }

          const drupArray = [];
          for (const item of payload[param]) {
            drupArray.push({ value: item });
          }
          drupalized_payload[param] = drupArray;
        } else if (param === 'same_tm' || param === 'field_fcm_tokens') {
          const drupArray = [];
          for (const item of payload[param]) {
            drupArray.push({ value: item });
          }
          drupalized_payload[param] = drupArray;
        } else if (param === 'applicants') {
          drupalized_payload[param] = payload[param];
        } else if (param === 'report_header') {
          drupalized_payload[param] = [
            { value: payload[param], format: 'full_html' },
          ];
        } else if (
          dateFields.indexOf(param) !== -1 &&
          payload[param] !== null
        ) {
          drupalized_payload[param].push({
            value: payload[param] + '+00:00',
          });
        } else if (param === 'items') {
          const drupArray = [];
          for (const item of payload[param]) {
            drupArray.push({ ...item, taxable: item.taxable ? 1 : 0 });
          }
          drupalized_payload[param] = drupArray;
        } else if (param === 'country') {
          drupalized_payload[param].push({
            value: payload[param]?.toUpperCase(),
          });
        } else {
          drupalized_payload[param].push({
            value: payload[param],
          });
        }
      }
    }
    return drupalized_payload;
  }

  format<T>(drupalObj: any) {
    const undrupalObj = {} as UndrupalObject;
    const untouchable = [
      'applicants',
      'applicant',
      'national_database',
      'wipo_database',
      'items',
    ];
    const hasValueProp = ['classes_details']; // params that contain their own (non-drupal) 'value' property
    for (const param in drupalObj) {
      if (Object.prototype.hasOwnProperty.call(drupalObj, param)) {
        if (drupalObj[param] instanceof Array) {
          if (untouchable.indexOf(param) !== -1) {
            undrupalObj[param] = drupalObj[param];
          } else if (param === 'context' && drupalObj[param].length) {
            undrupalObj[param] = JSON.parse(drupalObj[param][0].value);
          } else if (
            drupalObj[param].length === 1 &&
            param !== 'classes' &&
            param !== 'field_document_status'
          ) {
            if (
              drupalObj[param][0].value !== undefined &&
              hasValueProp.indexOf(param) === -1
            ) {
              undrupalObj[param] = drupalObj[param][0].value;
            } else {
              undrupalObj[param] = drupalObj[param][0];
            }
          } else if (
            drupalObj[param].length > 1 ||
            param === 'classes' ||
            param === 'field_document_status'
          ) {
            const cleanArray = [];
            for (const item of drupalObj[param]) {
              cleanArray.push(item.value);
            }
            undrupalObj[param] = cleanArray;
          }
        } else {
          undrupalObj[param] = drupalObj[param];
        }
      }
    }
    return undrupalObj as T;
  }

  fillObject(drupalObj: UndrupalObject, objToFill: any) {
    for (const param in drupalObj) {
      if (drupalObj.hasOwnProperty(param)) {
        if (drupalObj[param] instanceof Array) {
          if (param === 'applicants') {
            objToFill[param] = drupalObj[param];
          } else if (drupalObj[param].length === 1) {
            if (drupalObj[param][0].value !== undefined) {
              objToFill[param] = drupalObj[param][0].value;
            } else {
              objToFill[param] = drupalObj[param][0];
            }
          } else if (drupalObj[param].length > 1) {
            const cleanArray = [];
            for (const item of drupalObj[param]) {
              cleanArray.push(item.value);
            }
            objToFill[param] = cleanArray;
          }
        } else {
          objToFill[param] = drupalObj[param];
        }
      }
    }
    return objToFill;
  }

  cleanParams(params: any) {
    for (const param in params) {
      if (params.hasOwnProperty(param)) {
        if (
          params[param] === '' ||
          params[param] === null ||
          params[param] === undefined
        ) {
          delete params[param];
        }
      }
    }
    return params;
  }
}
