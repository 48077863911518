import { Observable, map } from 'rxjs';

import { Injectable, inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { PaginatedResponse } from '@pm/core';
import { Notification, NotificationStatus } from '@pm/notification/utils';

import * as NotificationsActions from './notifications.actions';
import * as NotificationsFeature from './notifications.reducer';
import * as NotificationsSelectors from './notifications.selectors';

@Injectable()
export class NotificationsFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(
    select(NotificationsSelectors.selectNotificationsLoaded),
  );
  allNotifications$ = this.store.pipe(
    select(NotificationsSelectors.selectAllNotifications),
  );
  selectedNotifications$ = this.store.pipe(
    select(NotificationsSelectors.selectEntity),
  );
  unreadNotifications$ = this.store.pipe(
    select(NotificationsSelectors.selectUnreadNotitifications),
  );
  unreadNotificationsCount$ = this.store.pipe(
    select(NotificationsSelectors.selectUnreadNotitificationsCount),
  );

  readNotifications$ = this.store.pipe(
    select(NotificationsSelectors.selectReadNotitifications),
  );
  getNotitifcationsByStatus$(status: NotificationStatus) {
    switch (status) {
      case NotificationStatus.UNREAD:
        return this.unreadNotifications$;
      case NotificationStatus.READ:
        return this.readNotifications$;
      default:
        return this.allNotifications$;
    }
  }

  getNotificationsByStatusPaginated$(
    status: NotificationStatus,
    page: number,
    pageSize: number,
  ): Observable<PaginatedResponse<Notification>> {
    return this.getNotitifcationsByStatus$(status).pipe(
      map((notifications) => {
        const start = page * pageSize;
        const end = start + pageSize;
        return {
          rows: notifications.slice(start, end),
          pager: {
            current_page: page,
            items_per_page: pageSize,
            total_items: notifications.length,
            total_pages: Math.ceil(notifications.length / pageSize),
          },
        };
      }),
    );
  }

  init() {
    this.store.dispatch(NotificationsActions.initNotifications());
  }

  markAsRead(id: number) {
    this.store.dispatch(NotificationsActions.markAsRead({ id }));
  }
}
