import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CacheBucket, HttpCacheManager, withCache } from '@ngneat/cashew';
import { PmAuthService } from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { PmCoreDrupalMapperService } from '@pm/core';
import {
  UserChange,
  UserChangeConfirmation,
  UserProfile,
} from '@pm/profile/utils';
import { from, map, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PmPorfileDataUserService {
  profileBucket = new CacheBucket();

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private _http: HttpClient,
    private readonly _auth: PmAuthService,
    private readonly _mapper: PmCoreDrupalMapperService,
    private readonly _manager: HttpCacheManager,
  ) {}

  getUserData$() {
    return from(this._auth.getDecodedToken()).pipe(
      switchMap((token) => {
        if (!token?.drupal?.uid) {
          return of(null);
        }
        return this._http
          .get<UserProfile>(`${this.appConfig.api}/user/${token.drupal.uid}`, {
            params: {
              _format: 'json',
            },
            context: withCache({
              bucket: this.profileBucket,
            }),
          })
          .pipe(
            map((user) => {
              return this._mapper.format<UserProfile>(user);
            }),
          );
      }),
    );
  }

  updateUserData$(data: Partial<UserProfile>) {
    const payload = this._mapper.drupalizeObject(data);
    return from(this._auth.getDecodedToken()).pipe(
      switchMap((token) =>
        this._http
          .patch(`${this.appConfig.api}/user/${token.drupal.uid}`, payload, {
            params: {
              _format: 'json',
            },
          })
          .pipe(
            tap(() => this._manager.delete(this.profileBucket)),
            map((user) => {
              return this._mapper.format<UserProfile>(user);
            }),
          ),
      ),
    );
  }

  changePassword$(password: string) {
    return this._http.post(
      this.appConfig.api + '/api/v1/user/password',
      { password },
      {
        params: { _format: 'json' },
      },
    );
  }

  sendPasswordConfirm$(code: string) {
    return this._http.patch(
      this.appConfig.api + '/api/v1/user/password',
      { code },
      {
        params: { _format: 'json' },
      },
    );
  }

  sendChanges$(data: UserChange) {
    return this._http.post<{ msg: string }>(
      this.appConfig.api + '/api/v1/user/changes',
      data,
      {
        params: { _format: 'json' },
      },
    );
  }

  confirmChanges$(data: UserChangeConfirmation) {
    return this._http.patch<{ msg: string }>(
      this.appConfig.api + '/api/v1/user/changes',
      data,
      {
        params: { _format: 'json' },
      },
    );
  }

  deleteSelf$() {
    return from(this._auth.getDecodedToken()).pipe(
      switchMap((token) =>
        this._http
          .delete(`${this.appConfig.api}/user/${token.drupal.uid}`, {
            params: {
              _format: 'json',
            },
          })
          .pipe(
            tap(() => {
              this._auth.signOut();
              window.location.href = '/';
            }),
          ),
      ),
    );
  }
}
