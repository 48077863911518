import { APP_INITIALIZER } from '@angular/core';

import { PmAuthService } from '../services/pm-auth-service.service';

export const authSsoProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (auth: PmAuthService) => {
    return async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const sso = urlParams.get('sso');
      if (sso) {
        await auth.setToken(sso);
        const allOtherParams = new URLSearchParams(window.location.search);
        allOtherParams.delete('sso');
        history.replaceState(
          null,
          '',
          allOtherParams.toString()
            ? window.location.pathname + '?' + allOtherParams
            : window.location.pathname,
        );
      }
      return;
    };
  },
  deps: [PmAuthService],
};
